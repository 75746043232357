<script>
import DisciplinesService from '@/service/disciplines.service';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import cloneDeep from 'lodash/cloneDeep';

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: 'modal-register-discipline',

  data() {
    return {
      dialog: false,
      loading: false,
      loadingSave: false,
      form: {},
    };
  },

  created() {
    this.$on('create', this.create);
    this.$on('edit', this.edit);
  },

  methods: {
    create() {
      this.form = {};
      this.dialog = true;
    },

    edit(id) {
      this.fetch(id);
      this.dialog = true;
    },

    async fetch(id) {
      this.loading = true;

      try {
        const customer = await DisciplinesService.get(id);
        this.form = customer;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    async save() {
      this.loadingSave = true;
      try {
        const form = cloneDeep(this.form);
        if (form.id) {
          const id = this.form.id;
          delete form.id;
          await DisciplinesService.update(id, form);
        } else {
          await DisciplinesService.create(form);
        }
        this.dialog = false;
        this.$swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Salvo com sucesso!',
          timer: 2000,
          showConfirmButton: false,
        });
        this.$emit('success');
      } catch (e) {
        console.error(e);
      }
      this.loadingSave = false;
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" persistent max-width="400px">
    <v-card :loading="loading">
      <v-card-title>
        <span class="text-h5">{{ !form.id ? 'Cadastro' : 'Edição' }} de disciplina</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12">
              <v-row>
                <v-col cols="12" sm="5">
                  <v-text-field
                    v-model="form.id"
                    label="ID"
                    color="white"
                    hide-details="auto"
                    disabled
                    outlined
                    persistent-placeholder
                    dense
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.name"
                    label="Nome"
                    color="white"
                    hide-details="auto"
                    outlined
                    persistent-placeholder
                    dense
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-checkbox
                    v-model="form.extracurricular"
                    color="white"
                    label="Extracurricular"
                    hide-details="auto"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false"> Cancelar </v-btn>
        <v-btn color="success" :loading="loadingSave" @click="save"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
